import React from "react";
import { Box, Container, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    padding: "50px 0px 0",
    zIndex: "2",
    overflow: "hidden",
    color: "rgba(255, 255, 255, 0.87)",
    backgoundColor: "rgba(255, 255, 255, 0.03)",
    "& .displayFlex": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .footerBox": {
      padding: "0px 10px",
      borderRight: "1px solid rgba(255, 255, 255, 0.87)",
      cursor: "pointer",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <Box className={classes.footerSection}>
      <Box className="copy" mt={1}>
        <Container>
          <Box
            alignItems="center"
            my={2}
            position="relative"
            flexWrap="wrap"
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="body1" style={{ fontWeight: "200" }}>
              © 2023 All Rights Reserved by Gulper.io
            </Typography>

            <Box className="displayFlex">
              <Box className="footerBox">
                <Typography variant="body1"> About</Typography>
              </Box>
              <Box className="footerBox">
                <Typography variant="body1"> Privacy</Typography>
              </Box>
              <Box className="footerBox">
                <Typography variant="body1"> v1.4.1</Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
} from "@material-ui/core";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { useWeb3React } from "@web3-react/core";
import { UserContext } from "src/context/User";
import ConnectButton from "src/component/ConnectButton";

const useStyles = makeStyles((theme) => ({
  header: {
    // height: "68px",
    // position: "absolute",
    backgroundColor: "#0C0C15",
    paddingRight: "0",
    paddingLeft: "0px",
    border: "1px solid #80808036",
    borderRadius: "10px",

    "@media (max-width: 1280px)": {
      paddingLeft: "0",
      paddingRight: "0px",
      height: "70px",
    },
    "@media (max-width: 900px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
      height: "70px",
    },
  },
  toolbar: {
    paddingLeft: "0px",
    paddingRight: "0px",
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },

  logoDrawer: {
    paddingLeft: "10px",
    width: "60px",
    // marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: theme.palette.background.footer,
    width: "260px",
  },
  drawericon: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "10px",
    right: "0px",
    fontSize: "25px",
  },
  logoImg: {
    width: "150px",

    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "100px",
    },
  },
  containerHeight: {
    height: "100%",
    fill: "rgba(255, 255, 255, 0.03)",
    strokeWidth: "1.5px",
    stroke: "rgba(255, 255, 255, 0.02)",
    filter: " drop-shadow(0px 8px 15px rgba(0, 0, 0, 0.15))",
    "& div": {
      alignItems: "center",
    },
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
}));

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useContext(UserContext);
  const { account } = useWeb3React();
  const {
    header,
    mainappbar,
    toolbar,
    drawerContainer,
    drawericon,
    logoImg,
    logoDrawer,
    containerHeight,
    mainHeader,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  // const [anchorEl1, setAnchorEl1] = React.useState(null);
  const handleClose5 = () => {
    setAnchorEl(null);
  };

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            style={{ paddingLeft: "0px" }}
          >
            {/* {getMenuButtons()} */}
            {!account && <ConnectButton style={{ margin: "0px 5px" }} />}
            {account && (
              <Button
                style={{ margin: "0px 5px" }}
                variant="contained"
                color="primary"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={() => user.disconnectWallet()}
              >
                Logout
              </Button>
            )}
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />

            {/* {getDrawerChoices()} */}

            <Button
              variant="contained"
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              style={{ marginTop: "2rem", marginLeft: "1rem" }}
            >
              Connect
            </Button>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose5}
            >
              <RouterLink to="/my-items" style={{ color: "#757373" }}>
                <MenuItem>My Items</MenuItem>
              </RouterLink>
              <MenuItem>
                {" "}
                {/* {account &&
                  `${account.substring(0, 4)}...${account.substring(
                    account.length - 4,
                    account.length
                  )}`}{" "} */}
                &nbsp;
                <CopyToClipboard
                  text={account}
                  style={{ color: "#757373", cursor: "pointer" }}
                >
                  <FiCopy onClick={() => toast.info("Copied")} />
                </CopyToClipboard>
              </MenuItem>
              <MenuItem>Disconnect</MenuItem>
            </Menu>
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon width="60px" height="60px" />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return (
      <Box>
        <Button
          component={RouterLink}
          to={"/create-show"}
          style={{ color: "#fff" }}
        >
          Create Shows
        </Button>
      </Box>
    );
  };

  const femmecubatorLogo = (
    <Box>
      <RouterLink to="/">
        <Logo className={logoImg} />
      </RouterLink>
    </Box>
  );

  const getMenuButtons = () => {
    return (
      <Box>
        <Button>Create Shows</Button>
      </Box>
    );
  };

  return (
    <>
      <Box className={mainappbar}>
        <AppBar className={header} elevation={0}>
          <Box
            maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
            className={containerHeight}
          >
            <Container>
              {mobileView ? displayMobile() : displayDesktop()}
            </Container>
          </Box>
        </AppBar>
      </Box>
    </>
  );
}

export const NetworkContextName = "NETWORK";
export const ACTIVE_NETWORK = 97;
export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";

export const NetworkDetails = [
  {
    chainId: "0x61",
    chainName: "Smart Chain - Testnet",
    nativeCurrency: {
      name: "tBNB",
      symbol: "tBNB",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
];

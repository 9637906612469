import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";
const useStyles = makeStyles((theme) => ({
  root: {
    // background: "#030209",
    background: "#060514",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <TopBar />

      <div>{children}</div>

      <Footer />
    </div>
  );
};

export default MainLayout;

import React, { createContext, useEffect, useState } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { getWeb3Obj } from "src/utils";
import { ACTIVE_NETWORK, NetworkDetails } from "src/constants";
export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [isOpenConnectWallet, setIsOpenConnectWallet] = useState(false);

  //-----*WEB3-Connection-Establisher*-----//
  const connectWalletHandler = () => {
    try {
      const connector = injected;
      localStorage.setItem("isConnected", true);
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }
      activate(connector, undefined, true).catch((error) => {
        if (error) {
          localStorage.removeItem("isConnected");
          // activate(connector);
          console.log("error", error);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.error(JSON.stringify(error.message));
    }
  };

  //-----*WEB3-Connection-Disable*-----//
  const disconnectWalletHandler = async () => {
    try {
      deactivate();
      // toast.success("You have been disconnected successfully!");
      window.localStorage.removeItem("userType");
      window.localStorage.removeItem("walletName");
      window.location = "/";
    } catch (error) {
      console.log(error);
    }
  };

  // account balance -----//
  const getUserbalce = async () => {
    const web3 = await getWeb3Obj();
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };

  //-----*WEB3-Network-Change-Request*-----//
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };
  //-----*WEB3-Network-Add-Request*-----//
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.warn(error.message);
    }
  };

  let data = {
    account,
    isOpenConnectWallet,
    yourWalletBalance,
    setIsOpenConnectWallet: (item) => setIsOpenConnectWallet(item),
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: () => connectWalletHandler(),
    disconnectWallet: () => {
      disconnectWalletHandler();
      // setIsLoading(false);
    },
  };

  useEffect(() => {
    data.updateUser(account);
    if (account) {
      setIsOpenConnectWallet(false);
    }
  }, [account, chainId]); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
    if (account) {
      getUserbalce();
    }
  }, [account]); //eslint-disable-line
  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler("hey");
        }
      }
    }
  }, [chainId, account]); //eslint-disable-line

  useEffect(() => {
    if (window.localStorage.getItem("isConnected")) {
      connectWalletHandler();
    }
  }, [window.localStorage.getItem("isConnected")]);

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}

import { UserContext } from "src/context/User";
import { Button } from "@material-ui/core";
import React, { useContext } from "react";

export default function ConnectButton(props) {
  const user = useContext(UserContext);
  return (
    <Button
      color="primary"
      variant="contained"
      {...props}
      onClick={() => user.connectWallet()}
    >
      Connect Wallet
    </Button>
  );
}

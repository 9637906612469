import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/game-play",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/GamePlay")),
  },
  {
    exact: true,
    path: "/players",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Player")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiAccordionDetails: {
      root: {
        padding: "10px",
        background: "#241A39",
        borderRadius: "10px",
        marginBottom: "5px",
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: "0px",
      },
    },
    MuiAccordion: {
      rounded: {
        background: "transparent",
        borderRadius: " 0",
        padding: "0px",
        border: "none",
        boxShadow: "none !important",
        borderBottom: "1px solid #8080802b",
      },
    },
    MuiIconButton: {
      root: {
        color: "#FFFFFF",
        "&.Mui-disabled": {
          color: "rgb(228 207 207 / 36%)",
          backgroundColor: "transparent",
        },
      },
    },
    MuiPaginationItem: {
      root: {
        color: "#FFFFFF",
      },
      page: {
        "&.Mui-selected": {
          backgroundColor: "#f5c843",
        },
        "&.Mui-selected:hover": {
          backgroundColor: "#f5c843 !important",
        },
      },
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },
    MuiPaper: {
      elevation1: {
        background: "rgba(255, 255, 255, 0.03)",
        border: "1px solid rgba(255, 255, 255, 0.15)",
        borderRadius: "10px",
        padding: "15px",
      },
      root: {
        backgroundColor: "none",
      },
      outlined: {
        padding: "20px",
        width: "100%",
      },
    },
    // MuiPaper: {
    //   root: {
    //     zIndex: 99999,
    //     background: "#0C0C0C",
    //   },
    // },
    MuiPickersModal: {
      dialogRoot: {
        backgroundColor: "#0C0C0C",
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#f5c843",
      },
      iconButton: {
        backgroundColor: "none",
        color: "#f5c843",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#f5c843",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "#FFFFFF",
      },
    },
    MuiTypography: {
      alignCenter: {
        color: "#FFFFFF",
      },

      subtitle1: {
        color: "#fff",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#f5c843",
        "&:hover": {
          backgroundColor: "#f5c843",
        },
      },
    },
    MuiListItem: {
      "& .Mui-selected": {
        color: "#FFFFFF",
        fontSize: "18px",
      },
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControl: {
      marginNormal: {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        borderRadius: "10px",
        height: "40px",
        paddingLeft: "8px",
        border: "2px solid rgba(255, 255, 255, 0.03)",
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperFullWidth: {
        padding: "15px",
        borderRadius: "15px",
        background: "#140c29",
        border: "8px solid #1E1929",
      },
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
      },
      paperWidthSm: {
        maxWidth: "900px !important",
      },
    },
    MuiInput: {
      underline: {
        "&::before": {
          borderBottom: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },
    MuiInputBase: {
      fullWidth: {
        border: "2px solid rgba(255, 255, 255, 0.03)",
        position: "relative",
        background: "rgba(255, 255, 255, 0.1)",
        borderRadius: "10px",
        paddingLeft: "8px",
      },
      input: {
        height: "40px",
        fontSize: " 14px",
        padding: "0px",
        color: "rgba(255, 255, 255, 0.6)",
        fontWeight: "300",
      },
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: "8px",
      },
      input: {
        padding: "2px 10px",
      },
      root: {
        background: "rgba(255, 255, 255, 0.1)",
        border: "1px solid rgba(255, 255, 255, 0.03)",
        borderRadius: "10px",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      root: {
        fontWeight: "500",
        fontSize: "14px",
        "&.Mui-disabled": {
          color: "#4d4646",
        },
      },
      textPrimary: {
        color: "#ffffff",
        backgroundColor: "#f5c843",
      },
      containedPrimary: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        color: "#fff",
        fontSize: "14px",
        borderRadius: "10px",
        background: "linear-gradient(156deg, #9969C7 0%, #2E1EDB 100%)",
        padding: "10px 19px",
      },

      containedSecondary: {
        backgroundColor: "#ffffff",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "50px",
        color: "#F5C843",
        fontSize: "15px",
        height: "40px",
        lineHeight: " 21px",
        padding: "10px 39px",
        marginRight: "10px",
        "&:hover": {
          backgroundColor: "#b2b2b2",
          color: "#fff",
        },
      },
      contained: {
        borderRadius: "50px",
        color: "#F5C843",
        fontWeight: 600,
        padding: "5px 19px",
        "&:hover": {
          backgroundColor: "#F5C843",
          color: "#fff",
        },
        "&.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26) !important",
          boxShadow: "none",
        },
      },
      outlinedPrimary: {
        borderRadius: "50px",
        color: "#FFFFFF",
        fontWeight: 600,
        padding: "7px 15px",
        border: "2px solid #F5C843",
        fontFamily: "'Michroma', sans-serif",
        boxShadow:
          "#f5c84338 1px 1px 3px 3px, #f5c84340 0px 0px 3px 3px, #f5c84338 -1px -1px 3px 3px",
        "&:hover": {
          backgroundColor: "#F5C843",
          border: "2px solid #F5C843",
          boxShadow: "none",
          color: "#ffffff",
        },
      },
      outlinedSecondary: {
        borderRadius: "50px",
        color: "#000000",
        fontWeight: 700,
        padding: "7px 15px",
        border: "2px solid #000000",
        fontFamily: "'Michroma', sans-serif",
        // boxShadow: "#f5c843d6 1px 1px 3px 3px, #f5c843d1 0px 0px 3px 3px, #f5c843cf -1px -1px 3px 3px",
        "&:hover": {
          backgroundColor: "#000000",
          border: "2px solid #000000",
          boxShadow: "none",
          color: "#ffffff",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    // MuiFormControl: {
    //   marginNormal: {
    //     marginTop: "0px",
    //     marginBottom: "0px",
    //   },
    // },
    MuiSelect: {
      icon: {
        color: "#FFFFFF",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
      list: {
        backgroundColor: "#0C0C0C",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
      },
      head: {
        whiteSpace: "pre",
      },
    },
    MuiMenuItem: {
      root: {
        marginLeft: "8px",
        color: "#FFFFFF",
        fontSize: "16px",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#0C0C0C",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
